
import {
  computed,
  defineComponent,
  PropType,
} from 'vue'
import {
  NotificationListItemLayout,
  NotificationListPosition,
} from '@/components/vue-notification.enum'
import { useNotificationStore } from '@/components/vue-notification.store'
import VueNotificationListItem from '@/components/VueNotificationListItem.vue'

export default defineComponent({
  name: 'VueNotificationList',
  components: {
    VueNotificationListItem,
  },
  props: {
    position: {
      type: String as PropType<NotificationListPosition>,
      default: NotificationListPosition.BottomRight,
    },
  },
  setup(props) {
    const { notifications } = useNotificationStore()

    const notificationList = computed(() => {
      if (notificationListPosition.value.includes(NotificationListItemLayout.Bottom)) {
        return notifications.value
      }

      return Object.keys(notifications.value).reverse().reduce((acc, cur) => ({
        ...acc,
        [cur]: notifications.value[cur],
      }), {})
    })

    const notificationListPosition = computed(
      () => Object.values(NotificationListPosition).includes(props.position)
        ? props.position
        : NotificationListPosition.BottomRight,
    )

    const classList = computed(() => `notification-list--${notificationListPosition.value}`)

    const notificationListItemLayout = computed(
      () => notificationListPosition.value.includes(NotificationListItemLayout.Left)
        ? NotificationListItemLayout.Left
        : NotificationListItemLayout.Right,
    )

    return {
      notificationList,
      classList,
      notificationListItemLayout,
    }
  },
})
