
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
} from 'vue'
import { Notification } from '@/components/vue-notification.interface'
import {
  NotificationListItemAppearance,
  NotificationListItemLayout,
  NotificationListItemType,
} from '@/components/vue-notification.enum'
import { useNotificationStore } from '@/components/vue-notification.store'
import { ICONS } from '@/components/vue-notification.constant'

export default defineComponent({
  name: 'NotificationListItem',
  props: {
    notification: {
      type: Object as PropType<Notification>,
      required: true,
    },
    layout: {
      type: String as PropType<NotificationListItemLayout>,
      default: NotificationListItemLayout.Left,
    },
  },
  setup(props) {
    const tagRef = ref<HTMLElement>()

    const { unsetNotification } = useNotificationStore()

    const notificationListItemType = computed(
      () => Object.values(NotificationListItemType).includes(props.notification.type)
        ? props.notification.type
        : NotificationListItemType.Info,
    )

    const notificationListItemLayout = computed(
      () => Object.values(NotificationListItemLayout).includes(props.layout)
        ? props.layout
        : NotificationListItemLayout.Left,
    )

    const notificationListItemAppearance = computed(
      () => Object.values(NotificationListItemAppearance).includes(props.notification.appearance)
        ? props.notification.appearance
        : NotificationListItemAppearance.Light,
    )

    const classList = computed(() => ({
      'notification-list-item--dismissible-manual': props.notification.dismiss.manually,
      'notification-list-item--dismissible-automatic': props.notification.dismiss.automatically && props.notification.showDurationProgress,
      [`notification-list-item--${notificationListItemType.value}`]: true,
      [`notification-list-item--${notificationListItemLayout.value}`]: true,
      [`notification-list-item--${notificationListItemAppearance.value}`]: true,
    }))

    const animationDurationStyle = ref({
      // duration - appear time - start delay - end delay
      animationDuration: `${props.notification.duration - 500 - 100}ms`,
    })

    const dismissManually = () => {
      if (!props.notification.dismiss.manually) return

      unsetNotification(props.notification.id)
    }

    const dismissAutomatically = () => {
      if (!props.notification.dismiss.automatically) return

      setTimeout(() => {
        unsetNotification(props.notification.id)
      }, props.notification.duration)
    }

    onMounted(() => {
      dismissAutomatically()
    })

    return {
      tagRef,
      classList,
      animationDurationStyle,
      icons: ICONS,
      dismissManually,
    }
  },
})
